var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--login"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--xl"
  }, [_vm._v("아이디 찾기")])]), _c('v-card', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "550"
    }
  }, [_c('div', {
    staticClass: "pa-30 px-lg-50 py-lg-70"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('u-text-field', {
    attrs: {
      "placeholder": "이메일",
      "type": "text"
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--input w-100",
    attrs: {
      "color": "primary lighten-4"
    },
    on: {
      "click": _vm.certify
    }
  }, [_vm._v("인증번호전송")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-text-field', {
    attrs: {
      "placeholder": "인증번호 입력",
      "type": "number"
    },
    model: {
      value: _vm.otp,
      callback: function ($$v) {
        _vm.otp = $$v;
      },
      expression: "otp"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-20 mt-md-30"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.recovery
    }
  }, [_vm._v("아이디 찾기")])], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }