<template>
    <v-text-field v-model="value" @input="$emit('input', value)" v-bind="$attrs" outlined hide-details><slot /></v-text-field>
</template>

<script>
export default {
    props: ["value"],
};
</script>

<style scoped></style>
