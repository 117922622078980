<template>
	<client-page class="app--login">
        <v-container>
            <div class="tit-wrap text-center">
                <h2 class="tit tit--xl">비밀번호 찾기</h2>
            </div>

            <v-card max-width="550" class="ma-auto">
                <div class="pa-30 px-lg-50 py-lg-70">
                    <v-row class="row--small">
                        <v-col cols="12">
                            <u-text-field v-model="username" placeholder="아이디" type="text"></u-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-row class="row--x-small">
                                <v-col cols="12" md="">
                                    <u-text-field v-model="email" placeholder="이메일" type="text"></u-text-field>
                                </v-col>
                                <v-col cols="12" md="auto">
                                    <v-btn @click="certify" color="primary lighten-4" class="v-btn--input w-100">인증번호전송</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <u-text-field v-model="otp" placeholder="인증번호 입력" type="number"></u-text-field>
                        </v-col>
                    </v-row>

                    <div class="mt-20 mt-md-30">
                        <v-btn block large color="primary" @click="recovery">비밀번호 찾기</v-btn>
                    </div>
                </div>
            </v-card>
        </v-container>
	</client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import UTextField from "@/components/client/dumb/u-text-field.vue";

export default {
	components: {
		ClientPage,
        UTextField,
	},
	data() {
		return {
			username: null,
			email: null,
			otp: null
		}
	},
	methods: {

		validate(recovery = false){
			try{
				if(!this.username) throw new Error("아이디를 입력해주세요");
				if(!this.email) throw new Error("이메일을 입력해주세요");
				if(recovery && !this.otp) throw new Error("인증번호를 입력해주세요");

				return true;
			}
			catch(error){
				alert(error.message);
			}
			return false;
		},

		async certify(){
			if(this.validate()){

				await api.v1.users.certify.post({
					type: "email",
					kind: "password",
					username: this.username,
					email: this.email
				});

				alert("인증번호가 전송되었습니다");
			}
		},

		async recovery(){
			if(this.validate(true)){
				await api.v1.users.recovery.post({
					type: "email",
					kind: "password",
					email: this.email,
					username: this.username,
					otp: this.otp
				});

				alert("가입한 이메일 정보로 비밀번호가 발송되었습니다");
			}
		},
	},
}
</script>
